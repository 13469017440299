import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip as MuiTooltip,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { conversation, upArrow, comissionEarned, valuePipeline, graphBars, infoIcon, } from './assets';
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { payment_pending } from "../../dashboard/src/assets";
import BoxSkeleton from "../../../components/src/BoxSkeleton";
import TableFilterSkeleton from "../../../components/src/TableFilterSkeleton";
import TableSkeleton from "../../../components/src/TableSkeleton";
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import { getCheckMarkIcon, getDateRangeText, capitalizeFirstLetter, getToken, calendarFilterArray, getCalendarByValue, getPageCount } from "../../../components/src/commonUsage";
import moment from "moment";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-calendar/dist/Calendar.css';
import { downArrow, searchIcon, down_fall } from "../../contractmanagement2/src/assets";
import { checkMark, emptyData } from "../../companycontactpage2/src/assets";
import { CSVLink } from "react-csv";
import { downloadIcon, emptyAssets } from "../../leadmanagement/src/assets";
import Pagination from "@material-ui/lab/Pagination";
import './css/style.css';
import { trackEvent } from "../../../components/src/analytics";
// Customizable Area End

import Commissionreports2Controller, {
  Props,
} from "./Commissionreports2Controller";

export default class Commissionreports2 extends Commissionreports2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getComissionData('normal')
    trackEvent('commission_report_accessed', "User clicks on Commission and Report section within the left navigation pane", { dewey_code: "B3.7", flow: 7 });
  }
  conversionValue(value: number, calanderValue: any) {
    if (calanderValue === 'All Time') {
      return null;
    }

    // Check if value is defined and not null
    if (typeof value !== 'undefined' && value !== null && value !== 0) {
      const isPositive = value > 0; // Adjusted to consider 0 as neither positive nor negative
      const labelTextClass = isPositive ? "grid-up-text" : "grid-down-text";
      const arrowImage = isPositive ? upArrow : down_fall;
      const arrowAltText = isPositive ? "Up Arrow" : "Down Arrow";

      return (
        <>
          <label className={labelTextClass}>{value.toString().replace("-", "")}%</label>
          <label><img src={arrowImage} alt={arrowAltText} /></label>
        </>
      );
    } else {
      return null; // Return null if value is undefined or null
    }
  }

  renderTable() {
    return <>
      {
        this.state.isTableLoading ?
          <TableSkeleton></TableSkeleton>
          :
          <Box className="commission-grid-wrapper">
            {
              this.state.comissionData.empty_state === false ?
                <Box style={{ minHeight: '450px' }}>
                  {
                    this.state.comissionData.leads.data.length > 0 ?
                      <Box>
                        <TableContainer className="lead-table" component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead style={{ background: '#f8f9fa', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                              <TableRow>
                                <TableCell style={{ width: '200px' }} className="lead-table-heading extraPadd">Deal name</TableCell>
                                <TableCell style={{ width: '200px' }} className="lead-table-heading">Company</TableCell>
                                <TableCell
                                  style={{ width: '180px' }}
                                  className="lead-table-heading"
                                >
                                  <Tooltip
                                    style={{ textAlign: 'inherit' }}
                                    title="DD/MM/YYYY"
                                    placement="top"
                                    arrow
                                    PopperProps={{
                                      style: { textAlign: 'center' },
                                      modifiers: {
                                        offset: {
                                          enabled: true,
                                          offset: '0, 8'
                                        }
                                      }
                                    }}>
                                    <label>Closed Date</label>
                                  </Tooltip>
                                </TableCell>
                                <TableCell style={{ width: '164px' }} className="lead-table-heading">VALUE</TableCell>
                                {/* <TableCell style={{ width: '120px' }} className="lead-table-heading">Potential commission</TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                this.state.comissionData.leads.data.map((item: any, index: any) => {
                                  return <TableRow key={index}>
                                    <TableCell data-test-id="lead" className="lead-table-data-company extraPadd" onClick={() => {
                                      this.navigateToSingleLead(item)
                                      }}>
                                      <span className="purple-text">{capitalizeFirstLetter(item.attributes.opportunity.opportunity_name)}</span>
                                    </TableCell>
                                    <TableCell onClick={() => this.navigateToLeads(item.attributes.company_lead)} style={{ cursor: 'pointer' }} className="lead-table-data-company">{capitalizeFirstLetter(item.attributes?.company_lead?.company_name)}</TableCell>
                                    <TableCell className="lead-table-data-row">{item.attributes.opportunity.close_date}</TableCell>
                                    <TableCell className="lead-table-data-row">{item.attributes.opportunity.sale_value}</TableCell>
                                    {/* <TableCell className="lead-table-data-row">{item.attributes.opportunity.commission}</TableCell> */}
                                  </TableRow>
                                })
                              }

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      :
                      <Box className="empty-data-block height-lg">
                        <Box className="empty-content">
                          <Box className="empty-img">
                            <img src={emptyAssets} />
                          </Box>
                          <Box className="content">
                            <Typography className="title-sm">No commissions found</Typography>
                            <Typography className="empty-sub-text">Try searching for something else or removing filters</Typography>
                          </Box>
                        </Box>
                      </Box>
                  }
                </Box>
                :
                <Box style={{ minHeight: '380px' }}>
                  <TableContainer
                    className="lead-table"
                    component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{
                          background: '#f8f9fa',
                          borderTop: '1px solid rgba(224, 224, 224, 1)'
                        }}
                      >
                        <TableRow>
                          <TableCell
                            style={{ width: '200px' }}
                            className="lead-table-heading">
                            Deal name
                            </TableCell>
                          <TableCell 
                          style={{ width: '200px' }} 
                          className="lead-table-heading">
                            Company
                          </TableCell>
                          <TableCell
                            style={{ width: '180px' }}
                            className="lead-table-heading">
                            <Tooltip
                              style={{ textAlign: 'inherit' }}
                              title="DD/MM/YYYY"
                              placement="top"
                              arrow
                              PopperProps={{
                                style: { textAlign: 'center' },
                                modifiers: {
                                  offset: {
                                    enabled: true,
                                    offset: '0, 8'
                                  }
                                }
                              }}>
                              <label>Closed Date</label>
                            </Tooltip>
                          </TableCell>
                          <TableCell style={{ width: '164px' }} className="lead-table-heading">VALUE</TableCell>
                          {/* <TableCell style={{ width: '120px' }} className="lead-table-heading">Potential commission</TableCell> */}
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <Box className="empty-data-block height-lg">
                    <Box className="empty-content">
                      <Box className="empty-img">
                        <img src={emptyData} />
                      </Box>
                      <Box className="content">
                        <Typography className="title-sm">No commissions yet</Typography>
                        <Typography className="empty-sub-text">Add more referrals to the dashboard, and the data will soon be generated.</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
            }
          </Box>
      }
    </>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { calendarMenu } = this.state;
    const sortCalendarOpen = Boolean(calendarMenu);
    return (
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Commission report', path: 'commission-report' }} />
              <Box style={webStyles.mainContainer}>
                {this.state.isLoading ?
                  <Box className="skeleton-wrapper">
                    <PageHeadingSkeleton />
                    <BoxSkeleton hasFourCols={true}></BoxSkeleton>
                    <TableFilterSkeleton hasTabbing={true}></TableFilterSkeleton>
                    <TableSkeleton></TableSkeleton>
                  </Box>
                  :
                  <>
                    <Box className="lead-dashboard-heading dashboard-nav-container">
                      <Box className="dashboard-heading">
                        <Box>
                          <Typography className="deal-heading-main">
                            Commission report
                            <Box className="tooltip-info-wrapper">
                              <MuiTooltip
                                classes={{ tooltip: 'custom-info-tooltip', }}
                                title="Gain valuable insights into your commission earnings with Commission Reporting. Track closed deals, monitor value in the pipeline, and stay informed about your financial commitments for optimized partnership strategies." placement="bottom" arrow>
                                <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                              </MuiTooltip >
                            </Box>
                          </Typography>
                          {
                            this.state.comissionData.empty_state === false ?
                              <Typography className="deal-heading-sub">
                                Get an overview of all the conversions and eligible commissions on your closed deals here.
                              </Typography>
                              :
                              <Typography className="deal-heading-sub">
                                Get an overview of all the conversions and eligible commissions on your closed deals here.
                              </Typography>
                          }
                        </Box>
                      </Box>
                      <Box>
                        <Grid className="dashboard-calendar-container" container>
                          <Grid item xs={12} md={12} style={webStyles.rightAlign}>
                            <div className="date-range-wrapper">
                              <p className="date-range-text">
                                Show data over
                              </p>
                              <div className="display-flex ml-6">
                                <Button
                                  className="select-deal-button"
                                  id="select-button"
                                  aria-haspopup="true"
                                  data-test-id={"btnCalendarMenuOpen"}
                                  onClick={this.handleCalendarClick}
                                  disableRipple
                                >
                                  <Typography className="select-stage-text">
                                    {getCalendarByValue(this.state.calanderValue)}
                                    <label className="dropdown-arrow">
                                      <img src={downArrow} />
                                    </label>
                                  </Typography>
                                </Button>
                                <Menu
                                  style={{
                                    top: "52px",
                                    borderRadius: "8px",
                                  }}
                                  id="select-menu"
                                  aria-labelledby="select-button"
                                  anchorEl={calendarMenu}
                                  open={sortCalendarOpen}
                                  onClose={this.handleCalendarClose}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                  anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                  }}
                                  PaperProps={{
                                    className: 'date-range-menu-paper', // Add your custom class name here
                                  }}
                                >
                                  {
                                    calendarFilterArray.map((item: any, index: any) => {
                                      return <MenuItem data-test-id={`selectCalanderMenu${index}`} key={item.value} className="menu-item-text" onClick={() => this.handleCalendarSelect(item.value)}>{item.label}{getCheckMarkIcon(item.value, checkMark, this.state.calanderValue)}</MenuItem>
                                    })
                                  }
                                </Menu>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="dashboard-datepicker-container" container>
                          {this.state.openCalendar && (
                            <div className="calander-picker">
                              <div style={{ height: "390px", width: "351px" }}>
                                <div>
                                  <Typography className="calander-range-text-label">Custom...</Typography>
                                  <Typography className="calander-range-text-value">{moment(this.state.dateRangeValue[0]).format('MMM D')} - {moment(this.state.dateRangeValue[1]).format('MMM D YYYY')}</Typography>
                                </div>
                                <hr />
                                <div style={{ height: "337px" }}>
                                  <DateRangePicker
                                    isOpen={this.state.openCalendar}
                                    value={this.state.dateValue}
                                    onChange={this.handleDateChange}
                                    className="daterange-picker-css"
                                  />
                                </div>
                                <hr />
                              </div>
                              <div style={webStyles.rightAlign}>
                                <Button data-test-id="close-calendar" onClick={() => this.closeCalander()}>
                                  <Typography className="cal-cancel-btn">
                                    Cancel
                                  </Typography>
                                </Button>
                                <Button data-test-id="get-filter-data" onClick={() => {
                                  this.setState({ dateRangeValue: this.state.dateValue }, () => {
                                    this.getComissionData('normal')
                                  })
                                }}>
                                  <Typography className="cal-apply-btn">
                                    Apply
                                  </Typography>
                                </Button>
                              </div>
                            </div>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                    {
                      this.state.comissionData.empty_state === false ?
                        <Box className="lead-section-one">
                          <Grid item xs={12} md={12} container>
                            <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                              <Box>
                                <Typography className="grid-text-heading">Total conversion</Typography>
                                <Box className="grid-counts">
                                  <Grid item xs={12} md={12} container className="grid-counts-inner">
                                    <Grid item className="icon-wrapper2">
                                      <img src={conversation} alt="conversation" />
                                    </Grid>
                                    <Grid item className="grid-count-wrap">
                                      <Typography className="grid-count-text">
                                        {this.state.comissionData.total_conversion}
                                        {
                                          this.conversionValue(this.state.comissionData.previous_conversion, this.state.calanderValue)
                                        }
                                      </Typography>
                                      <Typography className="grid-count-sub-text">
                                        {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                              <Box>
                                <Typography className="grid-text-heading">Commission earned</Typography>
                              </Box>
                              <Box className="grid-counts">
                                <Grid item xs={12} md={12} container className="grid-counts-inner">
                                  <Grid item className="icon-wrapper2">
                                    <img src={comissionEarned} alt="Deals" />
                                  </Grid>
                                  <Grid item className="grid-count-wrap">
                                    <Typography className="grid-count-text">
                                      {/* {this.state.comissionData.commission_earned}
                                      {
                                        this.conversionValue(this.state.comissionData.previous_commission, this.state.calanderValue)
                                      } */}$0
                                    </Typography>
                                    <Typography className="grid-count-sub-text">
                                      {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                              <Box>
                                <Typography className="grid-text-heading">Value in pipeline</Typography>
                              </Box>
                              <Box className="grid-counts">
                                <Grid item xs={12} md={12} container className="grid-counts-inner">
                                  <Grid item className="icon-wrapper2">
                                    <img src={valuePipeline} alt="Minimum commitement" />
                                  </Grid>
                                  <Grid item className="grid-count-wrap">
                                    <Typography className="grid-count-text">
                                      {this.state.comissionData.value_in_pipeline}
                                      {
                                        this.conversionValue(this.state.comissionData.previous_value_in_pipeline, this.state.calanderValue)
                                      }
                                    </Typography>
                                    <Typography className="grid-count-sub-text">
                                      {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        :
                        <Box className="lead-section-one">
                          <Grid item xs={12} md={12} container className="empty-lead-block">
                            <Grid className="lead-section-one-grid-empty-one" item xs={6} md={6}>
                              <Box>
                                <Typography className="grid-text-empty-heading">No commissions yet</Typography>
                              </Box>
                              <Box>
                                <Typography className="grid-text-empty-sub-heading">
                                  Add more referrals to the dashboard, and the data will soon be available for your review.
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid className="lead-section-one-grid-empty-two" item xs={6} md={6}>
                              <img src={graphBars} />
                            </Grid>
                          </Grid>
                        </Box>
                    }
                    <Box className="commission-tabbing-wrap">
                      <Box className="custom-tabbing-wrap">
                        <Tabs value={0}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#6200EA"
                            }
                          }}
                          onChange={(event, newValue) => {
                            this.handleTabChange(0)
                          }}
                          aria-label="basic tabs example" className="custom-tab-list">
                          <Tab disableRipple label="Commission details" />
                          {
                            (getToken('partner_role') === 'admin' && getToken('partner_id') === "null") &&
                            <Tooltip title="Coming soon" placement="top" arrow>
                              < Tab disableRipple label="Payouts" />
                            </Tooltip>
                          }
                        </Tabs>
                      </Box>
                      <Box className="custom-tab-content-wrap">
                        {
                          this.state.comissionData.empty_state === false ?
                            <Box className="commission-detail-wrapper">
                              <Box className="filter-block-wrapper ">
                                <Box className="table-search-text filter-inner-wrap">
                                  <Box className="filter-left-block">
                                    <Box className="filter-left-inner-wrap">
                                      {
                                        this.state.searchValue === '' ?
                                          <Typography className="search-results-text">Showing {this.state.comissionData.leads.data.length}</Typography>
                                          :
                                          <Typography className="search-results-text">{this.state.comissionData.leads.data.length} Search results for "{this.state.searchValue}"</Typography>
                                      }
                                    </Box>
                                  </Box>
                                  <Box className="filter-right-block">
                                    <Grid container style={webStyles.rightAlign}>
                                      <Grid className="mr-16">
                                        <TextField
                                          className="search-input"
                                          fullWidth
                                          size="small"
                                          placeholder="Search deals"
                                          variant="outlined"
                                          value={this.state.searchValue}
                                          onChange={(e) => this.handleSearchValue(e.target.value)}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <img src={searchIcon} className="ml-10" />
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                      <Grid>
                                        <Box>
                                          <Tooltip title="Download CSV" placement="top" arrow>
                                            <Button className="download-icon-btn" disabled={!this.state.comissionData.leads?.data?.length}>
                                              <CSVLink className="download-icon" filename="commission" data={this.state.csvData.data} headers={this.state.csvData.headers.map((item: any) => {
                                                return { label: item.label, key: item.label }
                                              })} >
                                                <img src={downloadIcon} />
                                              </CSVLink>
                                              </Button>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Box>
                              {
                                this.renderTable()
                              }

                            </Box>
                            :
                            <Box className="commission-detail-wrapper">
                              <Box className="filter-block-wrapper ">
                                <Box className="table-search-text filter-inner-wrap">
                                  <Box className="filter-left-block">
                                    <Box className="filter-left-inner-wrap">
                                      <Typography className="search-results-text">0 items</Typography>
                                    </Box>
                                  </Box>
                                  <Box className="filter-right-block">
                                    <Grid container style={webStyles.rightAlign}>
                                      <Grid className="mr-16">
                                        <TextField
                                          className="search-input"
                                          fullWidth
                                          size="small"
                                          placeholder="Search deals"
                                          variant="outlined"
                                          value={this.state.searchValue}
                                          onChange={(e) => this.handleSearchValue(e.target.value)}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <img src={searchIcon} className="ml-10" />
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                      <Grid>
                                        <Box>
                                          <Tooltip title="Download CSV" placement="top" arrow>
                                            <Button className="download-icon-btn" disabled>
                                              <CSVLink className="download-icon" filename="commission" data={this.state.csvData.data} headers={this.state.csvData.headers.map((item: any) => {
                                                return { label: item.label, key: item.label }
                                              })} >
                                                <img src={downloadIcon} />
                                              </CSVLink>
                                            </Button>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Box>
                              {
                                this.renderTable()
                              }

                            </Box>
                        }
                      </Box>
                    </Box>
                    {
                      this.state.comissionData.empty_state === false ? 
                      <>
                        {this.state.comissionData.leads.data.length > 0 ?
                          <Box className="table-page-info-block">
                            <Box className="pagination-label-wrapper">
                              <Typography className="pagination-text">
                                Showing {getPageCount(this.state.currentPage,this.state.comissionData.meta.total_count)} out of {this.state.comissionData.meta.total_count} results
                              </Typography>
                            </Box>
                            <Box className="pagination-wrapper">
                              <Pagination count={this.state.comissionData.meta.total_pages} onChange={(event, page) => this.handlePageChange(page)} page={this.state.currentPage || 1} shape="rounded" />
                            </Box>
                          </Box> : <></>
                        }
                      </>
                      : 
                      <></>
                    }                  
                  </>
                }
              </Box>
            </Grid>
          </Grid >
        </Grid >
      </Box >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
  tableContainer: {
    marginTop: 16,
    marginBottom: 16
  },
  centerAlign: {
    alignItems: 'center'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex'
  },
};
// Customizable Area End
